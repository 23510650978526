import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import { MEMBERS_ABOUT } from '@wix/app-definition-ids';
import { viewPost } from '@wix/ambassador-blog-v3-post/http';
import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  POST_STATUS,
  generatePostPageSEOTags,
  getAppConfig,
  getCustomPostUrl,
  toSeoTranslatedPage,
  resolveId,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_TOGGLE_POST_INDEX_SEO_SETTINGS } from '@wix/communities-blog-experiments';
import { AppData } from '../../../../viewer.app';
import { navigateToCustomPostUrl } from '../../../common/actions/navigate-within-blog';
import { pageOpened } from '../../../common/actions/page-opened';
import { extractMediaUrlsFromDraftOrRichContent } from '../../../common/helpers/extract-media-urls-from-draft-or-rich-content';
import { getQueryLocale } from '../../../common/selectors/locale-selectors';
import { POST_PAGE } from '../../../common/services/detect-route';
import {
  getIsMobile,
  isEditor,
  isSSR,
  isSite,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getCategoriesMap } from '../../../common/store/categories/categories-selectors';
import { getIsDemoMode } from '../../../common/store/instance-values/instance-values-selectors';
import {
  AppStore,
  FlowAPI,
  NormalizedPost,
  RedirectFn,
  WixCodeApi,
} from '../../../common/types';
import fetchPricingPlansUrl from '../../actions/fetch-princing-plans-url';
import { getReadingSessionId } from '../../selectors/reading-session-id-selector';
import { fetchRecentPostsAndComments } from '../fetch-data-for-post-widgets';

interface PostPageRouterPostHandlerParams {
  post: NormalizedPost;
  store: AppStore;
  redirect: RedirectFn;
  wixCodeApi: WixCodeApi;
  flowAPI: FlowAPI;
  appData: AppData;
}

export const postPageRouterPostHandler = async ({
  post,
  store,
  redirect,
  wixCodeApi,
  flowAPI,
}: PostPageRouterPostHandlerParams) => {
  const state = store.getState();
  // HACK: multilingual language change in post page redirect to translation
  const lang = getQueryLocale(state) as string;

  if (lang && lang !== post.language) {
    const translation = post.translations?.find(
      (trans) => trans.language === lang,
    );

    if (translation) {
      store.dispatch(
        navigateToCustomPostUrl(
          getCustomPostUrl(state, translation.slug),
          `/${translation.slug}`,
        ),
      );
      return;
    } else {
      const error = new Error('Post language mismatch');
      // @ts-expect-error
      error.status = 404;
      throw error;
    }
  }

  if (post.status !== POST_STATUS.published) {
    redirect(`/${resolveId(post)}/edit`);
  }

  if (post.canSeePaidContent === false) {
    await store.dispatch(fetchPricingPlansUrl(post));
  }

  const isMobile = getIsMobile(state);
  // @ts-expect-error
  const referrer = wixCodeApi.window.referrer;

  !isSSR(state) &&
    store.dispatch(
      pageOpened({
        page: POST_PAGE,
        post,
        isMobile,
        isEditor: isEditor(state),
        readingSessionId: getReadingSessionId(state),
        referrer,
      }),
    );

  if (!getIsDemoMode(state) && !isSSR(state) && !flowAPI.environment.isSEO) {
    flowAPI.httpClient.request(viewPost({ postId: resolveId(post) as string }));
  }

  if (isSite(state)) {
    let mediaUrls;

    if (post.content) {
      try {
        mediaUrls = extractMediaUrlsFromDraftOrRichContent(post.content);
      } catch (e) {}
    }

    let ownerUrl;

    try {
      const api = await wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID);

      if (api) {
        ownerUrl = await api.getSectionUrl({
          appDefinitionId: MEMBERS_ABOUT,
          sectionId: 'about',
          widgetId: '14dbefb9-3b7b-c4e9-53e8-766defd30587',
          memberId: post.owner?.slug,
        });
      }
    } catch (e) {}

    const translatedPages = (post?.translations ?? []).map((translation) =>
      toSeoTranslatedPage(
        translation,
        wixCodeApi.window.multilingual.siteLanguages,
      ),
    );

    const itemData = generatePostPageSEOTags({
      appConfig: getAppConfig(state),
      post,
      isTogglePostIndexSEOExperimentEnabled: isExperimentEnabled(
        state,
        EXPERIMENT_TOGGLE_POST_INDEX_SEO_SETTINGS,
      ),
      categoriesMap: getCategoriesMap(state),
      mediaUrls,
      customPostUrl: getCustomPostUrl(state, post.slug),
      multilingual: wixCodeApi.window.multilingual,
      ownerUrl,
      translatedPages,
    });

    console.log('##', { translatedPages, itemData });

    const postPageSEOTags = {
      itemType: ITEM_TYPES.BLOG_POST,
      itemData,
      seoData: post.seoData,
    };

    wixCodeApi.seo.renderSEOTags(postPageSEOTags);
  }

  await fetchRecentPostsAndComments({
    store,
    post,
  });
};
