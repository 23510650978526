import type { AppData } from '../../viewer.app';
import model from './model';
import type { PublicSettings, CommentsWidgetPublicApi } from './types';

const selectors = {
  root: '#commentsRoot',
  commentsSlot: '#slotComments',
};

export default model.createController(
  ({ $w, $widget, flowAPI, appData: _appData }) => {
    const appData = _appData as AppData;

    let components: ReturnType<typeof getComponents>;
    const getComponents = () => ({
      root: $w(selectors.root),
      commentsSlot: $w(
        selectors.commentsSlot,
      ) as $w.SlotsPlaceholder<CommentsWidgetPublicApi>,
    });

    const handleEvents = () => {
      if (components.commentsSlot.slot.onSettingsUpdated) {
        components.commentsSlot.slot.onSettingsUpdated(
          async ({ ratings, permissions }) => {
            $widget.fireEvent<PublicSettings>('commentsSettingsChanged', {
              ratings,
              permissions,
            });
          },
        );
      }
    };

    return {
      pageReady: async () => {
        components = getComponents();
        await appData.subjects.postPageRenderModel.subscribe((data) => {
          if (data.post.id) {
            components.commentsSlot.slot.resourceId = data.post.id;
          }

          const shouldLockComments =
            !data.post.commentingEnabled && flowAPI.environment.isViewer;

          if (shouldLockComments && components.commentsSlot.slot.lock) {
            components.commentsSlot.slot.lock();
          }
        });
        const isWidgetOnStage = !!components.root.id;
        if (isWidgetOnStage) {
          handleEvents();
        }
      },
      exports: {
        isCommentsPluginInstalled: (): boolean =>
          components.commentsSlot.slot.isMethodSupported('resourceId'),
        getSettigns: (): Promise<PublicSettings | undefined> => {
          if (components.commentsSlot.slot.getSettings) {
            return components.commentsSlot.slot.getSettings();
          }

          return Promise.resolve(undefined);
        },
      },
    };
  },
);
