import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

const createController = (({ $bind, $widget, flowAPI, $w }) => {
  const ui = {
    title: $w('#title') as $w.Text,
  } as const;

  return {
    pageReady: async () => {},
    exports: {
      initialize: ({ post }: PostPageRenderModel) => {
        ui.title.text = post.title ?? '';
      },
    },
  };
}) satisfies CreateController;

export type PostTitleApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
