import type { HttpClient, RequestOverrides } from '@wix/http-client';
import {
  getPostPage,
  getPostPagePreview,
  getPostPageMetadata,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';
import {
  createAction,
  type RequestClient,
  isExperimentEnabled,
  EXPERIMENTS,
  fetchTagsSuccess,
  fetchTPASettingsSuccess,
  fetchAppDataSuccess,
} from '@wix/communities-blog-client-common';
import type { AggregatorRequest } from '../../post-list-widget/types';
import { getWarmupKeyForOldPostPage } from '../helpers/get-warmup-data-key-for-old-post-page';
import { getLanguageCode } from '../selectors/locale-selectors';
import { getDemoPosts } from '../services/demo-posts';
import getEnvironment from '../services/get-environment';
import { normalizePost, normalizePostV3 } from '../services/post-utils';
import { getCategoryIds } from '../store/categories/categories-selectors';
import type { AppState, GetState, NormalizedPost } from '../types';
import type { FlowAPI, WixCodeApi } from '../types/platform-types';
import { fetchPostMetadataSuccess } from './fetch-post-metadata';

export const FETCH_POST_REQUEST = 'post/FETCH_REQUEST';
export const FETCH_POST_SUCCESS = 'post/FETCH_SUCCESS';
export const FETCH_POST_FAILURE = 'post/FETCH_FAILURE';

interface FetchPostRequestAction {
  type: typeof FETCH_POST_REQUEST;
  payload: { postSlug: string };
}

interface FetchPostSuccessAction {
  type: typeof FETCH_POST_SUCCESS;
  payload: { post: NormalizedPost; postSlug: string };
}

interface FetchPostFailureAction {
  type: typeof FETCH_POST_FAILURE;
  payload: { postSlug: string; error: unknown };
}

interface PreviewPostParams {
  includeDraft?: boolean;
  instance?: string;
}

interface MakeRequestParams {
  includeDraft?: boolean;
  instance?: string;
  httpClient: HttpClient;
  languageCode: string;
  dispatch: Function;
  getState: GetState;
  wixCodeApi: WixCodeApi;
  flowAPI: FlowAPI;
}

export type PostActionTypes =
  | FetchPostRequestAction
  | FetchPostSuccessAction
  | FetchPostFailureAction;

export const fetchPostRequest = createAction(FETCH_POST_REQUEST);
export const fetchPostSuccess = createAction(FETCH_POST_SUCCESS);
export const fetchPostFailure = createAction(FETCH_POST_FAILURE);

const makeRequestToPlatformizedApi = async (
  postSlugOrId: string,
  {
    includeDraft,
    instance,
    httpClient,
    languageCode,
    getState,
    dispatch,
    wixCodeApi,
    flowAPI,
  }: MakeRequestParams,
) => {
  const platformizedParams = {
    translationsName: 'main',
    languageCode,
    loadRichContent: true,
  };

  const request = includeDraft
    ? getPostPagePreview({
        draftPostId: postSlugOrId,
        ...platformizedParams,
      })
    : getPostPage({
        postId: postSlugOrId,
        ...platformizedParams,
      });

  try {
    const getInitialData = async () => {
      const requestOverrides: RequestOverrides | undefined = instance
        ? {
            signedInstance: instance,
          }
        : undefined;
      const [postPageResponse, postPageMetadataResponse] = await Promise.all([
        httpClient.request(request, requestOverrides),
        !includeDraft
          ? httpClient.request(
              getPostPageMetadata({
                postId: postSlugOrId,
                languageCode,
                skipViewCountIncrement: true,
              }),
              requestOverrides,
            )
          : null,
      ]);

      return { postPageResponse, postPageMetadataResponse };
    };

    let initialData: Awaited<ReturnType<typeof getInitialData>>;
    const warmupDataKey = getWarmupKeyForOldPostPage(postSlugOrId);

    const warmupDataGet = () => {
      try {
        const value = JSON.parse(
          wixCodeApi.window.warmupData.get(warmupDataKey),
        );
        return value;
      } catch {
        return undefined;
      }
    };

    if (
      flowAPI.essentials.experiments.enabled(
        EXPERIMENTS.USE_WARMUP_STATE_IN_OLD_POST_PAGE,
      ) &&
      flowAPI.environment.isSSR
    ) {
      initialData = await getInitialData();
      wixCodeApi.window.warmupData.set(
        warmupDataKey,
        JSON.stringify(initialData),
      );
    } else {
      initialData = warmupDataGet() ?? (await getInitialData());
    }

    const postPage = initialData?.postPageResponse.data?.postPage;
    const post = postPage?.post;

    if (!post || !postPage || typeof post.slug === 'undefined') {
      throw new Error('Post not found');
    }

    if (
      isExperimentEnabled(getState(), EXPERIMENTS.USE_POST_PAGE_ADAPTER_DATA)
    ) {
      dispatch(fetchTagsSuccess(postPage.tags));
      dispatch(
        fetchTPASettingsSuccess({ settings: postPage?.blogSettingsV2 as any }),
      );
      dispatch(
        fetchAppDataSuccess({
          ampEnabled: postPage?.blogSettingsV2?.ampEnabled ?? false,
        }),
      );
    }

    return {
      ...normalizePostV3(post),
      tags: postPage.tags ?? [],
      likeCount:
        initialData.postPageMetadataResponse?.data?.postPageMetadata
          ?.postMetadata?.likeCount ??
        post?.metrics?.likes ??
        0,
      totalComments:
        initialData.postPageMetadataResponse?.data?.postPageMetadata
          ?.postMetadata?.totalComments ??
        post.metrics?.comments ??
        0,
      averageRating:
        initialData.postPageMetadataResponse?.data?.postPageMetadata
          ?.postMetadata?.averageRating ??
        post.metrics?.averageRating ??
        0,
      totalRatings:
        initialData.postPageMetadataResponse?.data?.postPageMetadata
          ?.postMetadata?.totalRatings ??
        post.metrics?.totalRatings ??
        0,
      viewCount:
        initialData.postPageMetadataResponse?.data?.postPageMetadata
          ?.postMetadata?.viewCount ??
        post.metrics?.views ??
        0,
      /*
        To maintain compatibility with the old post page, we need to return the slug in the slugs array.
        It is used in the post page to determine if the post is avialable by slug or not.
      */
      slugs: [post.slug, postSlugOrId],
    };
  } catch (error: any) {
    if (error?.response?.status) {
      wixCodeApi.seo.setSeoStatusCode(error.response.status);
    } else {
      wixCodeApi.seo.setSeoStatusCode(500);
    }

    throw error;
  }
};

export const fetchPost = (
  postSlug: string,
  { includeDraft, instance }: PreviewPostParams = {},
) => {
  return (
    dispatch: (action: PostActionTypes) => void,
    getState: () => AppState,
    {
      httpClient,
      wixCodeApi,
      aggregatorRequest,
      flowAPI,
    }: {
      request: RequestClient;
      httpClient: HttpClient;
      wixCodeApi: WixCodeApi;
      aggregatorRequest: AggregatorRequest;
      flowAPI: FlowAPI;
    },
  ) => {
    dispatch(fetchPostRequest({ postSlug }));
    const state = getState();

    const promise = makeRequestToPlatformizedApi(postSlug, {
      httpClient,
      languageCode: getLanguageCode(state),
      includeDraft,
      instance,
      dispatch,
      getState,
      wixCodeApi,
      flowAPI,
    });

    return completeFetchPost(postSlug, promise)(dispatch, getState, {
      httpClient,
      wixCodeApi,
      aggregatorRequest,
    });
  };
};

export const preFetchPost =
  (postSlug: string, { includeDraft, instance }: PreviewPostParams = {}) =>
  (
    dispatch: (action: PostActionTypes) => void,
    getState: () => AppState,
    {
      httpClient,
      wixCodeApi,
      flowAPI,
    }: { httpClient: HttpClient; wixCodeApi: WixCodeApi; flowAPI: FlowAPI },
  ) => {
    dispatch(fetchPostRequest({ postSlug }));

    const state = getState();
    const languageCode = getLanguageCode(state);

    return makeRequestToPlatformizedApi(postSlug, {
      languageCode,
      includeDraft,
      instance,
      httpClient,
      dispatch,
      getState,
      wixCodeApi,
      flowAPI,
    });
  };

export const completeFetchPost =
  (postSlug: string, preFetchResult: Promise<NormalizedPost>) =>
  async (
    dispatch: (action: PostActionTypes) => void,
    getState: () => AppState,
    {
      wixCodeApi,
      httpClient,
      aggregatorRequest,
    }: {
      wixCodeApi: WixCodeApi;
      httpClient: HttpClient;
      aggregatorRequest: AggregatorRequest;
    },
  ) => {
    try {
      let post;
      let capturedError;

      try {
        post = await preFetchResult;
      } catch (e) {
        capturedError =
          e && typeof e === 'object' && 'response' in e ? e.response : e;

        if (
          capturedError &&
          typeof capturedError === 'object' &&
          'status' in capturedError &&
          capturedError.status === 404 &&
          getEnvironment(wixCodeApi).isEditorSegment
        ) {
          const resp = await getDemoPosts({
            httpClient,
            getState,
            dispatch,
            wixCodeApi,
            aggregatorRequest,
            query: { slugs: [postSlug] },
          });
          post = resp?.posts?.[0];
          if (post) {
            capturedError = null;
          }
        }
      }

      if (capturedError) {
        throw capturedError;
      }

      const normalizedPost = normalizePost({
        state: getState(),
        post,
        blogCategoryIds: getCategoryIds(getState()),
      });

      dispatch(fetchPostSuccess({ post: normalizedPost, postSlug }));
      dispatch(fetchPostMetadataSuccess(post) as any);

      return normalizedPost;
    } catch (error) {
      dispatch(fetchPostFailure({ postSlug, error }));
      throw error;
    }
  };
