import {
  POST_PUBLISHED,
  UPDATE_POST_COUNTERS,
} from '@wix/communities-blog-universal/src/constants/ws-events';
import { fetchPost } from '../../actions/fetch-post';
import { updatePostCounters } from '../../store/post-counters/post-counters-actions';

export default {
  [POST_PUBLISHED]: ({ postId }) => fetchPost(postId),
  [UPDATE_POST_COUNTERS]: updatePostCounters,
};
