import {
  Node_Type as NodeType,
  Node,
  ImageData,
  VideoData,
  GalleryData,
  GIFData,
} from 'ricos-schema';

const getImageURL = (entityData?: ImageData) => {
  const url = entityData?.image?.src?.id;
  if (!url) {
    return;
  }
  return `https://static.wixstatic.com/media/${url.replace('media/', '')}`;
};

const getVideoURL = (entityData?: VideoData) => {
  const thumbnailURL = entityData?.thumbnail?.src?.url;
  if (!thumbnailURL) {
    return;
  }
  return thumbnailURL.startsWith('media/')
    ? `https://static.wixstatic.com/${thumbnailURL}`
    : thumbnailURL;
};

const getImagesUrlsFromGallery = (entityData?: GalleryData) => {
  if (entityData?.items === undefined) {
    return;
  }
  return entityData?.items.map(
    (item) =>
      `https://static.wixstatic.com/media/${item?.image?.media?.src?.url}`,
  );
};

const getGifURL = (entityData?: GIFData) => {
  const videoThumbnailUrl = entityData?.original?.still;
  if (!videoThumbnailUrl) {
    return;
  }
  return videoThumbnailUrl;
};

const processNode = (node: Node, mediaURLs: string[]) => {
  if (
    [NodeType.IMAGE, NodeType.VIDEO, NodeType.GALLERY, NodeType.GIF].includes(
      node?.type as NodeType,
    )
  ) {
    if (node.videoData) {
      const videoURL = getVideoURL(node.videoData);
      if (videoURL) {
        mediaURLs.push(videoURL);
      }
    }
    if (node.imageData) {
      const imageURL = getImageURL(node.imageData);
      if (imageURL) {
        mediaURLs.push(imageURL);
      }
    }
    if (node.galleryData) {
      const galleryUrls = getImagesUrlsFromGallery(node.galleryData);
      if (galleryUrls) {
        mediaURLs.push(...galleryUrls);
      }
    }
    if (node.gifData) {
      const gifURL = getGifURL(node.gifData);
      if (gifURL) {
        mediaURLs.push(gifURL);
      }
    }
  }

  if (node.nodes && node.nodes.length > 0) {
    for (const childNode of node.nodes) {
      processNode(childNode, mediaURLs);
    }
  }
};

export const extractMediaUrls = (nodes: Node[]) => {
  const mediaURLs: string[] = [];
  if (!nodes) {
    return;
  }

  for (const node of nodes) {
    processNode(node, mediaURLs);
  }

  return mediaURLs;
};
