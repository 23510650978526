import { IWixAPI } from '@wix/yoshi-flow-editor';
import { SECTION_CATEGORY } from '@wix/communities-blog-client-common';
import { pageOpened } from '../../../common/actions/page-opened';
import { isPostContentRequired } from '../../../common/selectors/layout-selectors';
import {
  getIsValidPage,
  getLazyPaginationParams,
  getShowPagination,
} from '../../../common/selectors/pagination-selectors';
import { getSortedPostsByArchiveDate } from '../../../common/selectors/post-selectors';
import { ARCHIVE_PAGE } from '../../../common/services/detect-route';
import { generateArchiveSEOTags } from '../../../common/services/generate-seo-tags/generate-archive-seo-tags';
import { setMetaTagRobotsNoIndex } from '../../../common/services/set-metatag-robots-noindex';
import {
  getBasicParams,
  getTimezone,
  isSSR,
  isSite,
} from '../../../common/store/basic-params/basic-params-selectors';
import { fetchProfileUrls } from '../../../common/store/profile-urls/profile-urls-actions';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { AppStore, RouteResolverFn } from '../../../common/types';
import { fetchArchiveFeedRenderModel } from '../../actions/fetch-feed-render-model';
import { ROUTE_404, RouteArchiveParams } from '../../constants/routes';

export const createArchivePageRouter = (
  store: AppStore,
  wixCodeApi: IWixAPI,
): RouteResolverFn<RouteArchiveParams> => {
  return async ({ params }, redirect, { preFetch, preFetchResult }) => {
    const page = parseInt(params.pageIndex || '1', 10);
    const year = parseInt(params.year, 10);
    const month = parseInt(params.month, 10);

    const state = store.getState();
    const feedPosts = getSortedPostsByArchiveDate(state, params);
    const basicParams = getBasicParams(state);

    const actions = preFetchResult || [
      store.dispatch(
        fetchArchiveFeedRenderModel({
          page,
          archiveOptions: { year, month, timeZone: getTimezone(state) },
          pageSize: getLazyPaginationParams({
            state,
            section: SECTION_CATEGORY,
            page,
          })?.size,
          includeContent: isPostContentRequired(state, SECTION_CATEGORY),
          includeInitialPageData: feedPosts.length === 0,
          language: basicParams.language,
        }),
      ),
    ];

    if (preFetch) {
      return Promise.all(actions);
    }

    if (preFetchResult) {
      await preFetchResult;
      store.dispatch(fetchProfileUrls());
    } else {
      await Promise.all(actions);
    }

    if (isInvalidPage(params)) {
      return redirect(ROUTE_404);
    }

    addSeoTags(page, params);

    trackPageOpen();
  };

  function isInvalidPage(params: RouteArchiveParams) {
    const state = store.getState();
    const hasNoPosts = getSortedPostsByArchiveDate(state, params).length === 0;
    const showPagination = getShowPagination(state, SECTION_CATEGORY);
    const isValidPage = getIsValidPage(state, SECTION_CATEGORY);

    return (showPagination && !isValidPage) || hasNoPosts;
  }

  function addSeoTags(page: number, params: RouteArchiveParams) {
    const state = store.getState();
    const posts = getSortedPostsByArchiveDate(state, params);

    if (!isSite(state)) {
      setMetaTagRobotsNoIndex(wixCodeApi);

      return;
    }

    const sectionUrl = getSectionUrl(state);
    const archiveSEOTags = generateArchiveSEOTags({
      sectionUrl,
      state,
      page,
      posts,
      locale: wixCodeApi.site.regionalSettings,
      year: params.year,
      month: params.month,
      multilingual: wixCodeApi.window.multilingual,
    });
    wixCodeApi.seo.renderSEOTags(archiveSEOTags);
  }

  function trackPageOpen() {
    const state = store.getState();

    if (isSSR(state)) {
      return;
    }

    store.dispatch(pageOpened({ page: ARCHIVE_PAGE }));
  }
};
