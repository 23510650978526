import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  createAction,
  getCurrentUserSiteMemberId,
} from '@wix/communities-blog-client-common';
import getEnvironment from '../../../common/services/get-environment';

export const SET_VIEWED_USER = 'viewedUser/SET';

const setViewedUser = createAction(SET_VIEWED_USER);

export const fetchViewedUser =
  () =>
  async (dispatch, getState, { wixCodeApi }) => {
    const { isPreview, isEditor } = getEnvironment(wixCodeApi);
    if (isEditor || isPreview) {
      const currentUserSiteMemberId = getCurrentUserSiteMemberId(getState());
      dispatch(setViewedUser(currentUserSiteMemberId));
      return;
    }

    const api = await wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID);
    const viewedUser = await api.getViewedUser();
    dispatch(setViewedUser(viewedUser));
  };
