import type { Category } from '@wix/ambassador-blog-v3-category/types';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import { ALL_CATEGORIES, MAIN_CATEGORY } from './constants';
import model from './model';

const DEFAULT_VALUE: Record<string, string> = {
  header: MAIN_CATEGORY,
  footer: ALL_CATEGORIES,
};

interface RepeaterItem {
  _id: string;
  label: string;
  link: string;
}

const selectors = {
  categories: '#categoriesElement',
  categoriesRoot: '#categoriesRoot',
  categoiesRepeaterElement: '#category',
} as const;

const state = new Map<'categories', RepeaterItem[]>();
export default model.createController((params) => {
  const { $widget, $w } = params;

  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    categories: $w(selectors.categories) as $w.Repeater,
    categoriesRoot: $w(selectors.categoriesRoot) as $w.Box,
  });

  const applyCategoriesState = () => {
    const categories = state.get('categories') ?? [];

    const { hostWidget } = $widget.props;

    let categoriesToDisplay = $widget.props.categoriesToDisplay;

    if (!categoriesToDisplay && hostWidget in DEFAULT_VALUE) {
      categoriesToDisplay = DEFAULT_VALUE[hostWidget];
    }

    if (categories.length) {
      components.categories.data =
        categoriesToDisplay === MAIN_CATEGORY ? [categories[0]] : categories;
      components.categories.forEachItem(($item, itemData: RepeaterItem) => {
        $item(selectors.categoiesRepeaterElement).link = itemData.link;
        $item(selectors.categoiesRepeaterElement).label = itemData.label;
        $item(selectors.categoiesRepeaterElement).target = '_self';
      });
    } else {
      components.categoriesRoot.delete();
    }
  };

  const setCategories = (categories: Category[]) => {
    const normalizedCategories = categories.map((category) => {
      const url = category.url;
      const link = url && url.base && url.path ? `${url.base}${url.path}` : '';

      return {
        _id: category.id ?? '',
        label: category.label ?? '',
        link,
      };
    });
    state.set('categories', normalizedCategories);
    applyCategoriesState();
  };

  $widget.onPropsChanged(applyCategoriesState);

  return {
    pageReady: async () => {
      components = getComponents();
    },
    exports: {
      initialize: (renderModel: PostPageRenderModel) => {
        if (renderModel.categories) {
          setCategories(renderModel.categories);
        }
      },
    },
  };
});
