import type { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

const selectors = {
  tags: '#tagsElement',
  tagsRoot: '#tagsRoot',
  tagsRepeaterElement: '#category',
} as const;

const createController = (({ $w }) => {
  const components = {
    tags: $w(selectors.tags) as $w.Repeater,
    tagsRoot: $w(selectors.tagsRoot) as $w.Box,
  };

  const setTags = (tags: BlogTag[]) => {
    if (tags.length) {
      components.tags.data = tags.map((tag) => ({
        _id: tag.id ?? '',
        label: tag.label ?? '',
        link: tag.url?.base! + tag.url?.path!,
      }));

      components.tags.forEachItem(($item, itemData) => {
        $item(selectors.tagsRepeaterElement).label = itemData.label;
        $item(selectors.tagsRepeaterElement).link = itemData.link;
        $item(selectors.tagsRepeaterElement).target = '_self';
      });
    } else {
      components.tagsRoot.delete();
    }
  };

  return {
    pageReady: async () => {},
    exports: {
      initialize: (renderModel: PostPageRenderModel) => {
        setTags(renderModel.tags);
      },
    },
  };
}) satisfies CreateController;

export type PostTagsApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
