import { RichContent } from 'ricos-schema';
import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import type { IWixWindow } from '@wix/yoshi-flow-editor';
import { Category } from '@wix/ambassador-blog-v3-category/types';
import {
  AMP_BASE_URLS,
  POST_PAGE_URLS,
} from '@wix/communities-universal/dist/src/constants/wix-blogs';
import {
  getPostSeoDescription,
  getPostExcerpt,
  toSeoTranslatedPage,
  getFullLanguageCode,
  getPostAmpUrl,
  getCanonicalPostUrl,
} from '@wix/communities-blog-client-common';
import type { NormalizedPost } from '../../common/types/normalized-post.type';
import { extractMediaUrls } from './extract-media-urls';

type GenerateSeoDataParams = {
  post: NormalizedPost;
  baseURL: string;
  instanceId: string;
  mainCategoryDisplayName: string | undefined;
  categories: Category[] | undefined;
  multilingual: IWixWindow['multilingual'];
  postsSettings: {
    isAmpEnabled: boolean;
    isSchemaEnabled: boolean;
  };
};

export const generatePostPageSeo = (params: GenerateSeoDataParams) => {
  const {
    post,
    baseURL,
    instanceId,
    mainCategoryDisplayName,
    categories,
    multilingual,
    postsSettings,
  } = params;

  const excerpt = post.customExcerpt ? post.excerpt : undefined;

  const postFullURL = `${post.url?.base}${post.url?.path}`;
  const siteLanguages = multilingual.siteLanguages;

  const categoriesLabels = categories
    ?.map((category) => category.label)
    .join(', ');

  const wpAmpBaseUrl = AMP_BASE_URLS[instanceId];
  const ampBaseURL =
    wpAmpBaseUrl || (postsSettings.isAmpEnabled ? `${baseURL}/amp` : '');

  const translatedPages = (post?.translations ?? []).map((translation) =>
    toSeoTranslatedPage(translation, siteLanguages),
  );

  return {
    itemType: ITEM_TYPES.BLOG_POST,
    itemData: {
      post: {
        amphtml: getPostAmpUrl(post.internalId, ampBaseURL, post.slug),
        type: 'article',
        id: post?.id,
        title: post?.title,
        language: getFullLanguageCode(siteLanguages, post?.language ?? 'en'),
        description: getPostSeoDescription({
          seoDescription: undefined,
          content: post.richContent as RichContent,
          excerpt,
        }),
        mainCategory: mainCategoryDisplayName,
        categories: categoriesLabels,
        canonicalUrl: getCanonicalPostUrl({
          post: {
            url: post?.url,
            canonicalUrl: post?.canonicalUrl,
            slug: post?.slug,
          },
          postPageSectionUrl: POST_PAGE_URLS[instanceId],
          customPostUrl: undefined,
          multilingual,
        }),
        optInMeta:
          'max-snippet:-1, max-image-preview:large, max-video-preview:-1',
        slug: post?.slug,
        excerpt: getPostExcerpt({
          content: post?.richContent as RichContent,
          excerpt,
        }),
        contentImages: post?.richContent?.nodes
          ? extractMediaUrls(post.richContent.nodes)
          : [],
        lastPublishedDate: post?.lastPublishedDate,
        firstPublishedDate: post?.firstPublishedDate,
        owner: {
          name: post?.owner?.name,
          url: post?.owner?.image?.url,
        },
        path: postFullURL,
        image: post?.media?.wixMedia?.image,
      },
      postsSettings,
      translatedPages,
    },
    seoData: post?.seoData,
  };
};
