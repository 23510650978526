import { ButtonData_Type, Link_Target, type RichContent } from 'ricos-schema';
import type {
  I$W,
  IWixAPI,
  PlatformControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import { postContentElementIds } from './elementIds';

const PAYWALL_BUTTON_ID = 'c3gvh';

export class PostContentController {
  private readonly ui = {
    richContentViewer: this.$w(
      `#${postContentElementIds.richContentViewerId}`,
    ) as $w.RichContentViewer,

    paywallRichContentViewer: this.$w(
      `#${postContentElementIds.paywallRichContentViewerId}`,
    ) as $w.RichContentViewer,

    multiStateBox: this.$w(
      `#${postContentElementIds.multiStateBoxId}`,
    ) as $w.MultiStateBox,

    paywallRichContentWrapper: this.$w(
      `#${postContentElementIds.paywallRichContentWrapper}`,
    ) as $w.Box,
  };

  private readonly facade = new BlocksWidgetFacade(
    this.flowAPI,
    this.wixCodeApi,
  );

  constructor(
    private readonly $w: I$W,
    private readonly flowAPI: PlatformControllerFlowAPI,
    private readonly wixCodeApi: IWixAPI,
  ) {}

  async applyStateToUi(model: PostPageRenderModel) {
    const { post } = model;
    const postContent = post.richContent as RichContent;
    const paywallRichContent = post.paywallRichContent as
      | RichContent
      | undefined;
    const metaSiteId =
      this.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId ?? '';
    const isMobileView = this.flowAPI.environment.isMobile;
    const isEditor = this.flowAPI.environment.isEditor;

    if (isEditor || !post.preview) {
      this.ui.paywallRichContentWrapper.delete();
    }

    this.ui.richContentViewer.metaSiteId = metaSiteId;
    this.ui.richContentViewer.isMobileView = isMobileView;
    this.ui.richContentViewer.content = postContent;

    if (paywallRichContent && !isEditor && post.preview) {
      this.ui.paywallRichContentViewer.metaSiteId = metaSiteId;
      this.ui.paywallRichContentViewer.isMobileView = isMobileView;
      const content = paywallRichContent;
      const url = await this.facade.getUrlToPricingPage(post);
      const buttonNode = content.nodes[2];
      if (
        buttonNode.type === 'BUTTON' &&
        buttonNode.buttonData?.type === 'ACTION' &&
        buttonNode.id === PAYWALL_BUTTON_ID
      ) {
        buttonNode.buttonData.type = ButtonData_Type.LINK;
        buttonNode.buttonData.link = {
          url: new URL(model.getBaseURL() + url).href,
          anchor: undefined,
          target: Link_Target.SELF,
          rel: {
            nofollow: true,
            sponsored: false,
          },
        };
      }
      this.ui.paywallRichContentViewer.content = content;
    }
  }
}
