import model from './model';

const selectors = {
  root: '#socialRoot',
  shareButtons: '#shareButtons',
};

export default model.createController(({ $w }) => {
  return {
    pageReady: async () => {
      $w(selectors.shareButtons).pageToShare = 'current';
    },
    exports: {},
  };
});
