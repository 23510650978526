import type { IWixAPI } from '@wix/yoshi-flow-editor';
import type {
  AppData,
  BlogTag,
  PostPage,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import { Category } from '@wix/ambassador-blog-v3-category/types';
import {
  SITE_URLS,
  WP_BLOGS,
} from '@wix/communities-universal/dist/src/constants/wix-blogs';
import { getImageUrl } from '@wix/communities-blog-client-common';
import type { NormalizedPost } from '../common/types';
import { generatePostPageSeo } from './helpers/generate-seo-data';

type Params = {
  post: NormalizedPost;
  tags: BlogTag[];
  metadata: {
    viewCount: number;
    likeCount: number;
    totalComments: number;
    isLiked: boolean;
  };
  relatedPosts: PostPage['relatedPosts'];
  readingSessionId: string;
  categories: Category[] | undefined;
  appData?: AppData;
  wixCodeApi: IWixAPI;
  instanceId: string;
};

const isDefined = <T>(value: T | undefined): value is T => value !== undefined;

export class PostPageRenderModel {
  #instanceId: string;
  #wixCodeApi: IWixAPI;
  post: Params['post'];
  relatedPosts: Params['relatedPosts'];
  tags: Params['tags'];
  metadata: Params['metadata'];
  readingSessionId: string;
  categories?: Params['categories'];
  appData: Params['appData'];
  mainCategoryDisplayName?: string;
  seoTags: ReturnType<typeof generatePostPageSeo>;
  heroImageUrl?: string;

  constructor({
    instanceId,
    wixCodeApi,
    post,
    relatedPosts,
    categories,
    metadata,
    tags,
    readingSessionId,
    appData,
  }: Params) {
    this.#instanceId = instanceId;
    this.#wixCodeApi = wixCodeApi;

    this.post = post;
    this.relatedPosts = relatedPosts;
    this.metadata = metadata;
    this.tags = tags;
    this.categories = this.getCategories(categories);
    this.readingSessionId = readingSessionId;
    this.appData = appData;
    this.mainCategoryDisplayName = this.categories?.[0]?.label;
    this.seoTags = this.getSeoTags();
    this.heroImageUrl = this.post.heroImage
      ? getImageUrl({
          image:
            'src' in this.post.heroImage
              ? this.post.heroImage.src
              : this.post.heroImage,
        })
      : undefined;
  }

  get author() {
    const title = this.getFirstLastName();

    return {
      firstName: title.firstName,
      lastName: title.lastName,
      avatarUrl: getImageUrl({ image: this.post.owner?.image }),
    };
  }

  private getCategories(
    categories: Category[] | undefined,
  ): Category[] | undefined {
    const categoriesById = new Map(
      categories?.flatMap((category) => [
        [category.id, category],
        [category.internalId, category],
      ]),
    );
    if (!this.post?.categoryIds) {
      return undefined;
    }
    return this.post.categoryIds
      .map((id) => categoriesById.get(id))
      .filter(isDefined);
  }

  private getFirstLastName() {
    const [firstName, ...lastNameParts] =
      this.post?.owner?.name?.split(' ') ?? [];

    return {
      firstName,
      lastName: lastNameParts.join(' '),
    };
  }

  getBaseURL() {
    if (WP_BLOGS.includes(this.#instanceId)) {
      return SITE_URLS[this.#instanceId];
    }
    return this.#wixCodeApi.location.baseUrl;
  }

  private getSeoTags() {
    return generatePostPageSeo({
      post: this.post,
      baseURL: this.getBaseURL(),
      instanceId: this.#instanceId,
      categories: this.categories,
      mainCategoryDisplayName: this.mainCategoryDisplayName,
      multilingual: this.#wixCodeApi.window.multilingual,
      postsSettings: {
        isAmpEnabled: this.appData?.ampEnabled ?? false,
        isSchemaEnabled: true,
      },
    });
  }
}
