import { IWixAPI } from '@wix/yoshi-flow-editor';

type HandlerFn<T> = (payload: T) => Promise<void> | void;

/**
 * A store that fetches data on location change and notifies subscribers.
 */
export class LocationAwareStore<Payload> {
  #promiseRef: Promise<Payload> | undefined;
  #handlers: HandlerFn<Payload>[] = [];

  constructor(
    private readonly context: {
      wixCodeApi: IWixAPI;
      dataFn: () => Promise<Payload>;
    },
  ) {
    this.updatePromiseReferenceOnLocationChange();
  }

  subscribe = async (handler: HandlerFn<Payload>) => {
    this.#promiseRef ??= this.context.dataFn();

    const model = await this.#promiseRef;
    await handler(model);
    this.#handlers.push(handler);
  };

  private updatePromiseReferenceOnLocationChange() {
    this.context.wixCodeApi.location.onChange(async () => {
      if (this.#handlers.length === 0) {
        return;
      }

      this.#promiseRef = this.context.dataFn();

      const modelForNewLocation = await this.#promiseRef;

      for (const handler of this.#handlers) {
        handler(modelForNewLocation);
      }
    });
  }
}
