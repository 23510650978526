import { MODAL_TYPE_POST_SETTINGS } from '@wix/communities-blog-client-common';
import { OPEN_MODAL } from '../../modals/framework/store/modal-actions';
import { isPublished } from '../../selectors/is-published';

const handler = (action, state) => {
  if (action.payload.type !== MODAL_TYPE_POST_SETTINGS) {
    return;
  }

  return {
    evid: 228,
    eventMeta: {
      isNavigation: false,
      description: 'Advanced post settings open',
    },
    is_published: isPublished(state),
  };
};

export const uouShowModalEvent = { [OPEN_MODAL]: handler };
