import { isDraftContent, isRichContent } from 'ricos-content';
import { extractMedia as extractMediaUrlsFromDraftJS } from 'ricos-content/libs/extract-media';
import { extractMediaUrls as extractMediaUrlsFromRichContent } from '../../blocks-widget/helpers/extract-media-urls';

export const extractMediaUrlsFromDraftOrRichContent = (
  content: Record<string, any>,
): string[] | undefined => {
  if (isDraftContent(content)) {
    return extractMediaUrlsFromDraftJS(content).map(
      (media) => media.imageUrl || media.videoThumbnailUrl,
    );
  }

  if (isRichContent(content)) {
    return extractMediaUrlsFromRichContent(content.nodes);
  }
};
