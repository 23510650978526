import { IWixAPI } from '@wix/yoshi-flow-editor';
import {
  BLOG_POST_PAGE_SECTION_ID,
  BLOG_SECTION_ID,
  BLOG_APP_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';

const getSectionUrl = (sectionId: string) => async (wixCodeApi: IWixAPI) => {
  const result = await wixCodeApi.site.getSectionUrl({
    sectionId,
    appDefinitionId: BLOG_APP_ID,
  });

  return result.url || '';
};

export const getBlogFeedSectionUrl = getSectionUrl(BLOG_SECTION_ID);
export const getBlogPostPageSectionUrl = getSectionUrl(
  BLOG_POST_PAGE_SECTION_ID,
);
